import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Link } from "gatsby";
import DateString from "../components/DateString";
import { Recipe } from "../types";
import styled from "styled-components";
import Alert from "./Alert";

export type Props = {
  recipe: Recipe["node"];
};

const Table = styled.table`
  border-collapse: collapse;

  thead {
    background: ${(p) => p.theme.lightShades};
  }

  th,
  td {
    padding: 2px 32px;

    &:first-child {
      padding-left: 4px;
    }
  }
  th {
    font-weight: bold;
  }
`;

const A = styled.a`
  text-decoration: underline;

  &:hover {
    font-weight: bold;
  }
`;

const components = {
  table: Table,
  a: A,
};
const shortcodes = { Link, Alert, ...components }; // Provide common components here

const RecipePage: React.FC<Props> = ({ recipe }) => {
  return (
    <Container>
      <BlogTitle>
        <Header>{recipe.frontmatter.title}</Header>
        <SubHeader>
          <DateString date={recipe.frontmatter.date} />
        </SubHeader>
        <Tags>
          {recipe.frontmatter.tags.map((t) => (
            <span>{t}</span>
          ))}
        </Tags>
      </BlogTitle>
      <Content>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{recipe.body}</MDXRenderer>
        </MDXProvider>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 16px;
  max-width: 900px;
`;

const Content = styled.div`
  line-height: 1.5;
`;

const BlogTitle = styled.div`
  padding-top: 8px;
  border-bottom: 1px solid black;
`;

const Tags = styled.div`
  padding: 8px 0;
  display: flex;

  span {
    background: ${(p) => p.theme.lightShades};
    margin-right: 4px;
    border-radius: 16px;
    padding: 2px 8px;
    font-size: 16px;
  }
`;

const Header = styled.h1`
  margin: 0;
`;

const SubHeader = styled.h6`
  margin: 0;
`;

export default RecipePage;
