import React from "react";
import { graphql } from "gatsby";
import Main from "../components/Main";
import RecipePage from "../components/RecipePage";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  console.log(data);
  const node = data.allMdx.nodes[0];
  return (
    <Main title={node.frontmatter.title}>
      <RecipePage recipe={node} />
    </Main>
  );
}

export const pageQuery = graphql`
  query MyQuery($slug: String!) {
    allMdx(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        body
        frontmatter {
          title
          date
          tags
        }
      }
    }
  }
`;
