import React from "react";
import { format } from "date-fns";

type Props = {
  /** ISO 8601 string */
  date: string;
};

const DateString: React.FC<Props> = ({ date }) => (
  <>{format(new Date(date), "E, do MMMM yyyy")}</>
);

export default DateString;
