import React from "react";
import styled from "styled-components";

type Props = {};

const Alert: React.FC<Props> = ({ children }) => (
  <Container>{children}</Container>
);

const Container = styled.div`
  background: #d3efff;
  color: "#01466c";
  border-left: 8px solid #0288d1;
  margin: 8px 0;
  padding: 8px;
`;

export default Alert;
